import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

export const Navbar = ({ isAuthenticated }) => {
    const navigate = useNavigate();
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleLogoClick = () => {
        window.location.href = "http://www.patilgroup.com/";
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/LoginPatil');
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const closeNav = () => {
        setIsNavOpen(false);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className='d-flex flex-row'>
                <div className="navbar-brand" onClick={handleLogoClick}>
                    <img src={require('./logo.png')} alt="Logo" className='align-top logo-style' />
                </div>
                <div className='patil-address-container'>
                    <h5 className='patil-address-heading'>Patil Rail Infrastructure Pvt Ltd</h5>
                    <p className='patil-address'>205 & 206, Amar Chand Sharma Complex S.P. Road, Secunderabad – 500003 Telangana, INDIA.</p>
                </div>
            </div>


            <button
                className={`navbar-toggler ${isNavOpen ? '' : 'collapsed'}`}
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={isNavOpen ? 'true' : 'false'}
                aria-label="Toggle navigation"
                onClick={toggleNav}
            >
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarNav">
                <ul className="navbar-nav right-aligned-nav">
                    <li className="nav-item">
                        <a
                            href="http://www.patilgroup.com/" // External URL
                            className="nav-link"
                            onClick={closeNav}
                        >
                            Home
                        </a>
                    </li>

                    {!isAuthenticated && (
                        <li className="nav-item">
                            <Link
                                to="/LoginPatil" // Internal route
                                className="nav-link"
                                onClick={closeNav}
                            >
                                Login
                            </Link>
                        </li>
                    )}
                    {isAuthenticated && (
                        <li className="nav-item">
                            <button className="nav-link" onClick={handleLogout}>
                                Logout
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
};
